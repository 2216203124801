import request from '@/utils/request'

/**
 * 用户接口
 * */

// getUserInfo 获取用户信息接口
export function getUserInfo() {
  return request({
    url: `/user/info`,
    method: 'get'
  })
}

// getUserList 获取用户列表
// page num keyword order desc
export function getUserList(params) {
  return request({
    url: `/user/list`,
    method: 'get',
    params
  })
}

/**
 * 管理员接口
 * */

// getUserListByAdmin 获取用户列表
// page num keyword order desc
export function getUserListByAdmin(params) {
  return request({
    url: `/admin/user/list`,
    method: 'get',
    params
  })
}

// updateUserByAdmin 修改用户信息
export function updateUserByAdmin(data) {
  return request({
    url: `/admin/user/update`,
    method: 'put',
    data
  })
}
