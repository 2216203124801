import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from '@/store/index'
import {HashRouter} from 'react-router-dom'
import reportWebVitals from '@/reportWebVitals'
import PermissionAuth from '@/PermissionAuth'
import { ConfigProvider, DatePicker, message } from 'antd'
import '@/style/index.less'
import '@/style/global.less'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import './icons'
import '@/style/icon.less'
import Empty from '@/components/Empty'

ReactDOM.render(
  <ConfigProvider locale={zhCN} renderEmpty={() => <Empty />}>
    <Provider store={store}>
      <HashRouter>
        <PermissionAuth />
      </HashRouter>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

reportWebVitals()
