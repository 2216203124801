import React from 'react'
import SvgIcon from '../SvgIcon'

const Empty = () => {
  return <div className='empty-data'>
    <SvgIcon icon='empty_status' className='empty-status' />
  </div>
}

export default Empty
