import React, {useEffect, useState} from 'react'
import { Modal } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import Show from '@/components/Show'
import './style.less'

const CustomModal = (props) => {
  const {title, visible, width} = props

  const [current_title, setTitle] = useState(title)
  const [current_visible, setVisible] = useState(visible)

  useEffect(() => {
    setVisible(visible)
    setTitle(title)
  }, [title, visible])

  return <Show show={current_visible}>
    <Modal
      className={`custom-modal ${props.className ? props.className : ''}`}
      width={width || 808}
      closeIcon={<SvgIcon icon='close' />}
      title={current_title}
      visible={current_visible}
      onOk={props['submit']}
      onCancel={props['cancel']}
    >
      { props.children }
    </Modal>
  </Show>
}

export default CustomModal
