import { v4 as uuid } from 'uuid'

const init_state = {
  project_name: '',
  log_name: ''
}

export const analysis_unique = uuid()

const state = (state = init_state, action) => {
  let new_state = state
  if (action.type === analysis_unique) {
    new_state = {...new_state, ...action.data}
  }
  return new_state
}

export default state
