import React from 'react'
import ReactMarkdown from 'react-markdown'

const MarkdownPreview = (prop) => {
  const { value } = prop

  return <div>
    <ReactMarkdown>{value}</ReactMarkdown>
  </div>
}

export default MarkdownPreview
