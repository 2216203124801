import { createHashHistory } from 'history'
import {getRouterQuery} from 'format-function'
import { removeApiKey, removeToken, removeXToken } from '@/utils/token'
import defaultSettings from '@/config'
import dayjs from 'dayjs'
import SparkMD5 from 'spark-md5'
import React from 'react'
import DefaultTag from '@/components/Tag/DefaultTag'
import Template from '@/components/Template'

export const logout = () => {
  removeApiKey()
  removeToken()
  removeXToken()
  const { location } = createHashHistory()
  const { pathname } = location
  let redirectUrl = `http://${defaultSettings.domain}/#${pathname}`
  const url_query = getRouterQuery()
  let new_query = ''
  Object.keys(url_query).forEach((item, index) => {
    if (index !== (Object.keys(url_query).length - 1)) {
      new_query = new_query + `${item}=${url_query[item]}&`
    } else {
      new_query = new_query + `${item}=${url_query[item]}`
    }
  })
  if (new_query.length > 0) {
    redirectUrl = redirectUrl + `?${new_query}`
  }
  const baseUrl = encodeURIComponent(`${redirectUrl}`)
  window.location.href = `${defaultSettings.ssoLogout}?redirectURL=${baseUrl}`
}

export const login = () => {
  window.location = `${defaultSettings.sso}${encodeURIComponent(window.location.href)}`
}

export const deleteUrlToken = () => {
  const { location } = createHashHistory()
  const { pathname } = location
  let redirectUrl = `http://${window.location.host}/#${pathname}`
  const url_query = getRouterQuery()
  delete url_query.token
  let new_query = ''
  Object.keys(url_query).forEach((item, index) => {
    if (index !== (Object.keys(url_query).length - 1)) {
      new_query = new_query + `${item}=${url_query[item]}&`
    } else {
      new_query = new_query + `${item}=${url_query[item]}`
    }
  })
  if (new_query.length > 0) {
    redirectUrl = redirectUrl + `?${new_query}`
  }
  window.location.href = redirectUrl
}

export const deleteUrlXToken = () => {
  const { location } = createHashHistory()
  const { pathname } = location
  let redirectUrl = `http://${window.location.host}/#${pathname}`
  const url_query = getRouterQuery()
  delete url_query.authorization
  let new_query = ''
  Object.keys(url_query).forEach((item, index) => {
    if (index !== (Object.keys(url_query).length - 1)) {
      new_query = new_query + `${item}=${url_query[item]}&`
    } else {
      new_query = new_query + `${item}=${url_query[item]}`
    }
  })
  if (new_query.length > 0) {
    redirectUrl = redirectUrl + `?${new_query}`
  }
  window.location.href = redirectUrl
}

export const deleteQuote = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const computeFileSize = (bytes) => {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (bytes / Math.pow(k, i)).toFixed(1) + ' ' + sizes[i]
}

export const downloadFileByBlobData = (data, filename = null) => {
  if (!data) {
    return
  }
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', filename)
  link.click()
}

export const routerQuery = (path, condition) => {
  let query = ''
  if (condition && Object.keys(condition).length) {
    Object.keys(condition).forEach((item, index) => {
      if ((index + 1) !== Object.keys(condition).length) {
        query = query + `${item}=${condition[item]}&`
      } else {
        query = query + `${item}=${condition[item]}`
      }
    })
  }
  if (query.length) {
    return (path + '?' + query)
  }
  return path
}

export const getTimeDifference = (time) => {
  if (!time) return ''
  const init_time = null
  const years = dayjs(dayjs().format()).diff(time, 'year')
  const days = dayjs(dayjs().format()).diff(time, 'day')
  const hours = dayjs(dayjs().format()).diff(time, 'hour')
  const minutes = dayjs(dayjs().format()).diff(time, 'minute')
  if (years > 1) {
    return dayjs(time).format('YYYY-MM-DD')
  }
  if (years < 1 && days > 2) {
    return dayjs(time).format('MM-DD HH:mm')
  }
  if (days === 1) {
    return `昨天 ${dayjs(time).format('HH:mm')}`
  }
  if (days === 0) {
    if (hours > 0) {
      return (hours + '小时前')
    } else {
      return (minutes + '分钟前')
    }
  }
  return init_time
}

export const replaceMailbox = (mailbox) => {
  if (mailbox && mailbox.includes('@dbappsecurity')) {
    return mailbox.replace('@dbappsecurity', '.***')
  }
  return mailbox || ''
}

export function computedFileMd5WithSlice(file, callback) {
  return new Promise((resolve, reject) => {
    var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice // 分割函数
    var chunkSize = 100 * 1024 * 1024 // 50mb分割
    var chunks = Math.ceil(file.size / chunkSize) // 分割的个数
    var currentChunk = 0 // 当前chunk
    var spark = new SparkMD5() // spark实例
    var fileReader = new FileReader() // fileReader对象
    fileReader.onload = function(e) {
      spark.appendBinary(e.target.result)
      currentChunk += 1
      if (currentChunk < chunks) {
        loadNext()
        callback(currentChunk, chunks)
      } else {
        const md5 = spark.end()
        resolve({ md5: md5 })
        callback(currentChunk, chunks)
      }
    }
    fileReader.onerror = function() {
      reject(new Error('file reader error'))
    }
    function loadNext() {
      var start = currentChunk * chunkSize
      var end = start + chunkSize >= file.size ? file.size : start + chunkSize
      fileReader.readAsBinaryString(blobSlice.call(file, start, end))
    }
    loadNext()
  })
}

export const chunkFile = (file) => {
  return new Promise(resolve => {
    var func = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    var chunkSize = 100 * 1024 * 1024 // 100mb切割文件
    var chunks = Math.ceil(file.size / chunkSize)
    var chunkBuffer = []
    for (let i = 1; i <= chunks; i++) {
      const currentChunk = func.call(file, (i - 1) * chunkSize, ((i * chunkSize >= file.size) ? file.size : i * chunkSize))
      chunkBuffer.push(currentChunk)
    }
    resolve({ list: chunkBuffer })
  })
}

export const getHistogramHeight = (length) => {
  return (length * 52 + 'px')
}

export const showThumbnailTag = (list, ele, bool, className) => {
  const target = document.getElementsByClassName(ele)[0]
  if (target) {
    let len = target.clientWidth
    let temp_list = []
    if (bool && list && list.length) {
      list.forEach(item => {
        temp_list.push({text: item})
      })
    } else {
      temp_list = list
    }
    let last_index
    temp_list.sort((a, b) => {
      return a['text'].length - b['text'].length
    })
    temp_list.forEach((item, index) => {
      item.show = false
      const length = item.text.length
      if ((len - length * 8 - 16) > 0) {
        len = len - length * 8 - 16 - 4
        item.show = true
        last_index = index
      } else {
        item.total = (temp_list.length - last_index - 1) || 1
      }
    })

    return <div className='list-item-warp'>
      <Template show={temp_list.length === 1}>
        <div>
          <DefaultTag text={temp_list[0].text} />
        </div>
      </Template>
      <Template show={temp_list.length !== 1 && temp_list.length !== 0}>
        {
          temp_list.map((item, index) => {
            if (!item.total && item.show) {
              return <div key={index} className={className}>
                <DefaultTag text={item.text} />
              </div>
            }

            if (!item.show && index === temp_list.length - 1) {
              return <div key={index} className={className}>
                <DefaultTag text={`+${item.total}...`} />
              </div>
            }
          })
        }
      </Template>
      <Template show={!temp_list.length}>
        -
      </Template>
    </div>
  }
  return '-'
}

export const slicePage = (list, page, size) => {
  const total = list.length
  const count = page * size
  if (count < total || total === count) {
    return list.slice((page - 1) * size, count)
  }

  const max_count = (page - 1) * size

  if (count > total && total > max_count) {
    return list.slice(max_count)
  }
}

export const deleteTotal = (total, condition) => {
  let bool = false
  if ((total - 1) === ((condition.page - 1) * condition.size)) {
    condition.page = condition.page - 1
    bool = true
  }
  if (condition.page < 1) {
    condition.page = 1
  }
  return {bool, condition}
}
