import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getRouterQuery } from 'format-function'
import { user_unique } from '@/store/modules/user'
import { system_unique } from '@/store/modules/system'
import { routes_unique } from '@/store/modules/routes'
import { back_router, front_router, other_router } from '@/router'
import { generateRoutes } from '@/utils/route'
import { useMount } from 'ahooks'
import Layout from '@/layout'
import { getToken, setToken, setApiKey, getXToken, setXToken, getNoticeTip, setNoticeTip} from '@/utils/token'
import { getSystemInfo } from '@/api/system'
import { getUserInfo } from '@/api/user'
import Show from '@/components/Show'
import { login, deleteUrlToken, deleteUrlXToken } from '@/global-funcition/index'
import {createHashHistory} from 'history'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'ahooks'
import NoticeModal from '@/components-bus/PermissionAuth/NoticeModal'

const PermissionAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [state, setState] = useSetState({
    is_release: false,
    show_notice_modal: false,
    notice_content: ''
  })

  const getSystemDetails = async() => {
    return new Promise((resolve, reject) => {
      getSystemInfo().then(res => {
        const data = res.data || {}
        dispatch({type: system_unique, data})
        resolve(data)
      })
    })
  }

  const getInfo = async() => {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const data = res.data || {}
        const user_info = {...data, token: res.token || ''}
        resolve(user_info)
      })
    })
  }

  const setRoutes = (role) => {
    dispatch({type: routes_unique, data: {
      back_router: generateRoutes(back_router, role === 1 ? 'admin' : 'user'),
      front_router: generateRoutes(front_router, role === 1 ? 'admin' : 'user'),
      other_router: generateRoutes(other_router, role === 1 ? 'admin' : 'user')
    }})
  }

  const beforeEach = async() => {
    let token = getToken()
    const x_token = getXToken()

    const url = getRouterQuery()

    if (url.token) {
      token = url.token
      setToken(url.token)
      deleteUrlToken()
    }

    if (url['authorization']) {
      token = url['authorization']
      setXToken(url['authorization'])
      deleteUrlXToken()
    }

    const systemInfo = await getSystemDetails()

    if (!token && !x_token && systemInfo['AdminApiKey']) {
      setApiKey(systemInfo['AdminApiKey'])
      const user_info = await getInfo()
      dispatch({type: user_unique, data: {...user_info}})
      setRoutes(user_info.role)
      const { location } = createHashHistory()
      const { pathname } = location
      if (pathname === '/') {
        navigate('/front/project/list')
      }
      setState({is_release: true})
      return
    }

    if (systemInfo['NoticeContent']) {
      const notice = getNoticeTip()
      if (!notice) {
        setNoticeTip()
        setState({notice_content: systemInfo['NoticeContent'], show_notice_modal: true})
      }
    }

    if ((token && token.length) || (x_token && x_token.length)) {
      const user_info = await getInfo()
      dispatch({type: user_unique, data: {...user_info}})
      setRoutes(user_info.role)
      const { location } = createHashHistory()
      const { pathname } = location
      if (pathname === '/') {
        navigate('/front/project/list')
      }
      setState({is_release: true})
      return
    }

    // if (x_token && x_token.length) {
    //   const user_info = await getInfo()
    //   dispatch({type: user_unique, data: {...user_info}})
    //   setRoutes(user_info.role)
    //   const { location } = createHashHistory()
    //   const { pathname } = location
    //   if (pathname === '/') {
    //     navigate('/front/project/list')
    //   }
    //   setState({is_release: true})
    //   return
    // }

    login()
  }

  const handleCancel = () => {
    setState({show_notice_modal: false})
  }

  useMount(async() => {
    await beforeEach()
  })

  return <Show show={state.is_release}>
    <Layout />
    <NoticeModal visible={state.show_notice_modal} cancel={handleCancel} content={state.notice_content} />
    <div id='confirm' />
  </Show>
}

export default PermissionAuth
