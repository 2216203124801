import Axios from 'axios'
import { message } from 'antd'
import {getToken, getApiKey, getXToken} from './token'
import {login} from '@/global-funcition'

// 创建axios实例
const http = Axios.create({
  baseURL: '',
  withCredentials: true
})

// request 拦截
http.interceptors.request.use(
  config => {
    config.url = `${process.env.REACT_APP_BASE_API}${config.url}`
    const token = getToken()
    const x_token = getXToken()
    const api_key = getApiKey()

    if (api_key) {
      config.headers['Api-Key'] = api_key
      return config
    }

    if (token) {
      config.headers['X-Token'] = token
      return config
    }

    if (x_token) {
      config.headers['Authorization'] = x_token
      return config
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response 拦截
http.interceptors.response.use(
  response => {
    // 下载文件不拦截, 设置返回数据的content-type类型
    const headers = ['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-msdos-program', 'application/x-zip-compressed', 'application/zip', 'text/plain; charset=utf-8']
    if (headers.includes(response.headers['content-type'])) {
      return response.data
    }
    const res = response.data

    if (res.code === 401 && res.status === -1) {
      message.error('登录出现了问题，正在跳转到登录页面...')
      login()
    }

    if (res.code !== 0) {
      message.error(`抱歉，本次请求出现问题啦，${res.message}` || 'Error')
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    message.error(`抱歉，本次请求出现问题啦，${error.response.data.message}` || 'Error')
    return Promise.reject(error)
  }
)

export default http
