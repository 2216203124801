import { v4 as uuid } from 'uuid'

const init_state = {}

export const system_unique = uuid()

const state = (state = init_state, action) => {
  let new_state = state
  if (action.type === system_unique) {
    new_state = {...new_state, ...action.data}
  }
  return new_state
}

export default state
