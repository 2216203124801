import React from 'react'

const Show = (props) => {
  const { show } = props
  return <div className={props.className ? props.className : ''}>
    { show ? props.children : '' }
  </div>
}

export default Show
