import React from 'react'
import './style.less'

const DefaultTag = (props) => {
  const { text } = props
  return <div className={`default-tag ${props.className ? props.className : ''}`}>
    { text }
  </div>
}

export default DefaultTag
