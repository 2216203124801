import lazyLoading from '@/utils/lazyLoading'
import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import SvgIcon from '../components/SvgIcon'
// import Statistics from '../views/back/Statistics'
/* 前台路由*/
export const front_router = [
  {
    path: 'front',
    meta: { title: 'Home' },
    element: lazyLoading(() => import('@/layout/FrontLayout')),
    children: [
      {
        path: 'personal',
        meta: { title: '个人中心' },
        element: lazyLoading(() => import('@/views/front/Personal/index')),
        name: 'front'
      },
      {
        path: 'notice',
        meta: { title: '消息通知' },
        element: lazyLoading(() => import('@/views/front/Notice/index')),
        name: 'front'
      },
      {
        path: 'download',
        meta: { title: '下载中心' },
        element: lazyLoading(() => import('@/views/front/Download/index')),
        name: 'front'
      },
      {
        path: 'analysis',
        meta: { title: '应急分析' },
        element: lazyLoading(() => import('../views/front/Analysis/index')),
        name: 'front'
      },
      {
        path: 'project',
        meta: { title: '项目管理' },
        element: lazyLoading(() => import('@/components/RouterView/index')),
        children: [
          {
            path: 'list',
            meta: { title: '项目管理', icon: <UploadOutlined /> },
            element: lazyLoading(() => import('@/views/front/Project/List/index'))
          },
          {
            path: 'detail',
            meta: { title: '项目详情', icon: <UploadOutlined /> },
            element: lazyLoading(() => import('@/views/front/Project/Detail/index'))
          }
        ]
      },
      {
        path: 'upload',
        meta: { title: '文件上传' },
        element: lazyLoading(() => import('@/views/front/Upload/index')),
        name: 'front'
      },
      {
        path: '*',
        hidden: true,
        meta: { title: 'error' },
        element: lazyLoading(() => import('@/views/back/ErrorPage/index'))
      }
    ]
  }
]

/* 后台路由*/
export const back_router = [
  {
    path: 'bgd',
    element: lazyLoading(() => import('@/layout/BackLayout')),
    meta: { title: 'bgd', roles: ['admin'] },
    children: [
      // {
      //   path: 'dashboard',
      //   meta: { title: '后台首页', icon: <SvgIcon icon='house' /> },
      //   element: lazyLoading(() => import('@/views/back/Dashboard/index'))
      // },
      {
        path: 'statistics',
        meta: { title: '统计概览', icon: <SvgIcon icon='statistics' /> },
        element: lazyLoading(() => import('@/views/back/Statistics/index'))
      },
      {
        path: 'user',
        meta: { title: '用户管理', icon: <SvgIcon icon='use' />, roles: ['admin'] },
        element: lazyLoading(() => import('@/views/back/user/index'))
      },
      {
        path: 'project',
        meta: { title: '项目管理', icon: <SvgIcon icon='project_admin' /> },
        element: lazyLoading(() => import('@/components/RouterView/index')),
        children: [
          {
            path: 'list',
            meta: { title: '项目列表', icon: <SvgIcon icon='project_list_menu' />, roles: ['admin']},
            element: lazyLoading(() => import('@/views/back/ProjectManagement/ProjectList/ProList'))
          },
          {
            path: 'detail',
            hidden: true,
            meta: { title: '项目详情', icon: <UploadOutlined /> },
            element: lazyLoading(() => import('@/views/back/ProjectManagement/ProjectDetails/ProDetails'))
          }
        ]
      },
      {
        path: 'log',
        meta: { title: '日志类型管理', icon: <SvgIcon icon='log_admin' /> },
        element: lazyLoading(() => import('@/views/back/LogType/index'))
      },
      {
        path: 'rule',
        meta: { title: '规则管理', icon: <SvgIcon icon='rule_admin' /> },
        element: lazyLoading(() => import('@/views/back/RuleManagement/index'))
      },
      {
        path: 'emergency',
        meta: { title: '应急工具管理', icon: <SvgIcon icon='tool_admin' /> },
        element: lazyLoading(() => import('@/views/back/Emergency/index'))
      },
      {
        path: '*',
        hidden: true,
        meta: { title: 'error', icon: <UploadOutlined /> },
        element: lazyLoading(() => import('@/views/back/ErrorPage/index'))
      }
    ]
  }
]

/* 其他的路由*/
export const other_router = [
  {
    path: '',
    element: lazyLoading(() => import('@/layout/OtherLayout')),
    children: [
      {
        path: 'login',
        element: lazyLoading(() => import('@/views/other/Login/index')),
        name: 'login'
      },
      {
        path: '*',
        hidden: true,
        meta: { title: 'error', icon: <UploadOutlined />, roles: ['admin'] },
        element: lazyLoading(() => import('@/views/back/ErrorPage/index'))
      }

    ]
  }
]
