import React from 'react'
import { useSetState, useUpdateEffect} from 'ahooks'
import Modal from '@/components/Modal'
import MarkdownPreview from '@/components/MarkdownPreview/MarkdownPreview'

const NoticeModal = (props) => {
  const { content, visible } = props

  const [state, setState] = useSetState({
    content,
    visible,
    show_modal: false
  })

  useUpdateEffect(() => {
    setState({visible})
  }, [visible])

  useUpdateEffect(() => {
    setState({content})
  }, [content])

  const handleCancel = () => {
    props['cancel'] ? props['cancel']() : ''
  }

  return <div>
    <Modal
      className='custom-modal'
      width={808}
      title='平台公告'
      visible={state.visible}
      submit={handleCancel}
      cancel={handleCancel}
    >
      <MarkdownPreview value={state.content} />
    </Modal>
  </div>
}

export default NoticeModal
