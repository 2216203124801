import Cookies from 'js-cookie'

const TokenKey = 'token'

const XToken = 'x_token'

const ApiKey = 'ApiKey'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  const num = 24
  const time = new Date(new Date().getTime() + num * 60 * 60 * 1000)
  return Cookies.set(TokenKey, token, {
    expires: time
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function updateToken(token) {
  Cookies.remove(TokenKey)
  return Cookies.set(TokenKey, token)
}

export function getXToken() {
  return Cookies.get(XToken)
}

export function setXToken(token) {
  const num = 24
  const time = new Date(new Date().getTime() + num * 60 * 60 * 1000)
  return Cookies.set(XToken, token, {
    expires: time
  })
}

export function removeXToken() {
  return Cookies.remove(XToken)
}

export function updateXToken(token) {
  Cookies.remove(XToken)
  return Cookies.set(XToken, token)
}

export function getApiKey() {
  return Cookies.get(ApiKey)
}

export function setApiKey(token) {
  const num = 24 * 365 * 10
  const time = new Date(new Date().getTime() + num * 60 * 60 * 1000)
  return Cookies.set(ApiKey, token, {
    expires: time
  })
}

export function removeApiKey() {
  return Cookies.remove(ApiKey)
}

export function updateApiKey(token) {
  Cookies.remove(ApiKey)
  return Cookies.set(ApiKey, token)
}

export function getNoticeTip() {
  return Cookies.get('notice-tip')
}

export function setNoticeTip() {
  const num = 3
  const time = new Date(new Date().getTime() + num * 60 * 60 * 1000)
  return Cookies.set('notice-tip', 'notice-tip', {
    expires: time
  })
}

export function removeNoticeTip() {
  return Cookies.remove('notice-tip')
}
